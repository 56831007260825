#rendered-form  {
    all:none;
  }

  button {
      background-color:lightgreen;
  }

  label {
    align-items: center;
    grid-template-columns: auto 1fr;
  }
  input {
    background: white;
    color: rgb(7 2 18);
    transition: all 200ms ease;
    border-left: 0 solid transparent;
    border: 0;
    border: 1px solid black;
    padding: 0 calc(5.8rem * 0.5);
    border-radius: calc(5.8rem * 0.3);
    box-shadow: 0 0 2rem rgb(0, 0, 0, 20%);
  }
  label {
    display: inline;
  }
  label::after {
    content: "";
    display: block;
  }

  body {
    display: flex;
    min-height: 100vh;
    place-items: center;
    place-content: center;
}

  form {
    display: grid;
    grid-gap: 2rem;
  }

  textarea {
      width: 350px;
      height: 200px;
  }
#intro, #package, #small-text, #specifications {
    height: 60px;
 
}